import * as T from './users.type'
import * as Q from './users.query'
import { ApiResponse, mutate, query } from './api'
import { Success } from './types'

interface UsersApiInterface {
    signIn(args: T.UserArgs): ApiResponse<T.User>,
    register(args: T.UserArgs): ApiResponse<T.User>,
    getUsers(): ApiResponse<T.User[]>
}

export const UsersApi: UsersApiInterface = {
    signIn(args) {
        return mutate({
            mutation: Q.signIn,
            variables: { args }
        }).then(({ data }) => ({ data: data.signIn }))
    },
    register(args) {
        return mutate({
            mutation: Q.register,
            variables: { args }
        }).then(({ data }) => ({ data: data.register }))
    },
    getUsers() {
        return query({
            query: Q.getUsers
        }).then(({ data }) => ({ data: data.users }))
    },
}