import { gql } from '@apollo/client'

export const getMuscles = gql`
    query Muscles {
        muscles {
            id
            title{
                en
                nl
            }
        }
    }
`

export const getMuscle = gql`
    query Muscle($id: String!) {
        muscle(id: $id) {
            id
            title{
                en
                nl
            }
        }
    }
`

export const createMuscle = gql`
    mutation CreateMuscle($args: MuscleInput!) {
        createMuscle(args: $args) {
            success
        }
    }
`

export const updateMuscle = gql`
    mutation UpdateMuscle($id: String!, $args: MuscleInput!) {
        updateMuscle(id: $id, args: $args) {
            success
        }
    }
`