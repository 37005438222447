import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DataGridPro, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid-pro'
// import { DataGrid, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { CircularProgress, Fab, FormControl, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import { Add } from '@mui/icons-material'
import { LoadingOverlay } from '../../../components'
import { useDataContext } from '../../../context'
import { RoleTypes } from '../../../api'

export const Users = () => {
    const { isLoading, hasLoadedUsers, hasError, users, getUsers } = useDataContext()
    const { t, i18n } = useTranslation()
    let navigate = useNavigate()

    useEffect(() => {
        !isLoading && !hasLoadedUsers && getUsers()
    }, [])

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : hasError ? (
                <h2>Error.</h2>
            ) : users && hasLoadedUsers && (
                <div className='pb-16 flex flex-col gap-4'>
                    <h1 className='text-5xl text-cherny-primary'>{t('Users')}</h1>
                    <DataGridPro 
                        columns={[
                            { field: 'email', headerName: 'Email', flex: 1 },
                            { field: 'createdAt', headerName: 'Created At', valueFormatter: (params) => t('dateTime', { val: params.value }) },
                            // { 
                            //     field: 'actions',
                            //     type: 'actions',
                            //     getActions: (params) => [
                            //         <GridActionsCellItem icon={<EditIcon />} onClick={() => navigate(`/users/edit/${params.row.id}`)} label="Edit" />,
                            //         <GridActionsCellItem disabled={isLoading} icon={<DeleteIcon />} onClick={(e) => console.log(e, params.row.id)} label="Delete" />,
                            //     ]
                            // }
                        ]}
                        rows={users.filter(user => user.role !== RoleTypes.ADMIN)}
                        // onRowClick={(e) => handleSelected(e.row)}
                        autoHeight
                        disableSelectionOnClick
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        components={{
                            LoadingOverlay: LoadingOverlay,
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'desc' }]
                            },
                        }}
                        loading={isLoading}
                        className='shadow-mui bg-white'
                    />

                    {/* <Fab 
                        color="secondary" 
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                        }}
                        onClick={() => navigate('/users/add')}
                    >
                        <Add />
                    </Fab> */}
                </div>
            )}
        </>
    )
}