import { ApiResponse, mutate } from "./api";
import * as Q from './notifications.query'

interface NotificationsApiInterface {
    sendNotifications(body: string, title: string, url?: string): ApiResponse<{ success: boolean}>
}

export const NotificationsApi: NotificationsApiInterface = {
    sendNotifications(body, title, url) {
        return mutate({
            mutation: Q.sendNotifications,
            variables: { body, title, url }
        }).then(({ data }) => ({ data: data.sendNotifications }))
    },
}