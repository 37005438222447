import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AdminRoutes } from './pages';

function App() {
    return (
        // user?.role === Role.ADMIN ? (
            <AdminRoutes />
        // ) : (
        //     <p>You have no permission</p>
        // )
    );
}

export default App;
