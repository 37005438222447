import { ApiResponse, mutate, query } from "./api";
import * as T from './categories.type'
import * as Q from './categories.query'

interface CategoriesApiInterface {
    getCategories(): ApiResponse<T.Category[]>
    getCategory(id: string): ApiResponse<T.Category>
    createCategory(args: T.CategoryInput): ApiResponse<{ success: boolean }>
    updateCategory(id: string, args: T.CategoryInput): ApiResponse<{ success: boolean }>
}

export const CategoriesApi: CategoriesApiInterface = {
    getCategories() {
        return query({
            query: Q.getCategories
        }).then(({ data }) => ({ data: data.categories }))
    },
    getCategory(id) {
        return query({
            query: Q.getCategory,
            variables: { id }
        }).then(({ data }) => ({ data: data.category }))
    },
    createCategory(args) {
        return mutate({
            mutation: Q.createCategory,
            variables: { args }
        }).then(({ data }) => ({ data: data.createCategory }))
    },
    updateCategory(id, args) {
        return mutate({
            mutation: Q.updateCategory,
            variables: { id, args }
        }).then(({ data }) => ({ data: data.updateCategory }))
    },
}