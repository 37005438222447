import { client } from '../utils/apollo'
import { ApiResponse, mutate, query } from './api'
import * as T from './muscles.type'
import * as Q from './muscles.query'

interface MusclesApiInterface {
    getMuscles(): ApiResponse<T.Muscle[]>
    getMuscle(id: string): ApiResponse<T.Muscle>
    createMuscle(args: T.MuscleInput): ApiResponse<{ success: boolean }>
    updateMuscle(id: string, args: T.MuscleInput): ApiResponse<{ success: boolean }>
}

export const MusclesApi: MusclesApiInterface = {
    getMuscles() {
        return query({
            query: Q.getMuscles
        }).then(({ data }) => ({ data: data.muscles }))
    },
    getMuscle(id) {
        return query({
            query: Q.getMuscle,
            variables: { id }
        }).then(({ data }) => ({ data: data.muscle }))
    },
    createMuscle(args) {
        return mutate({
            mutation: Q.createMuscle,
            variables: { args }
        }).then(({ data }) => ({ data: data.createMuscle }))
    },
    updateMuscle(id, args) {
        return mutate({
            mutation: Q.updateMuscle,
            variables: { id, args }
        }).then(({ data }) => ({ data: data.updateMuscle }))
    },
}