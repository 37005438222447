import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import cuid from "cuid";
import {
    Button,
    CircularProgress,
    Switch,
    FormControlLabel,
} from "@mui/material";
import { useDataContext } from "../../context";
import { LoadingButton } from "@mui/lab";

interface Image {
    id: string;
    src: string | undefined;
}

interface UploadMediaProps {
    modal?: boolean;
    ebook?: boolean;
    open?: () => void;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    selectedMedia?: string;
    handleMediaSelect?: (id: string) => void;
}

const Image = ({ image }: { image: Image }) => {
    if (image.src?.endsWith("mp4")) {
        return (
            <div className="file-item">
                <video
                    src={image.src}
                    className="h-full w-26 pr-2 object-cover"
                />
            </div>
        );
    }
    return (
        <div className="file-item">
            <img
                alt={`img - ${image.id}`}
                src={image.src}
                className="h-full w-26 pr-2 object-cover"
            />
        </div>
    );
};

const ImagesGrid = ({ images }: { images: Image[] }) => {
    const renderImage = (image: Image, index: number) => {
        return <Image image={image} key={`${image.id}-image`} />;
    };

    return (
        <section className="flex flex-wrap w-auto py-2 px-4 my-4 mx-8">
            {images.map(renderImage)}
        </section>
    );
};

export const UploadMedia = ({
    modal = false,
    ebook = false,
    open,
    setOpen,
    selectedMedia,
    handleMediaSelect,
}: UploadMediaProps) => {
    const [images, setImages] = useState<Image[]>([]);
    const [mediaUpload, setMediaUpload] = useState<File[]>([]);
    const {
        uploadMedia,
        isLoading,
        media,
        hasLoadedMedia,
        hasError,
        getMedia,
        uploadEbook,
        uploadVideo,
    } = useDataContext();
    const [wantUploadVideo, setWantUploadVideo] = useState(false);
    const [video, setVideo] = useState<File | null>(null);
    const [subsEn, setSubsEn] = useState<File | null>(null);
    const [subsNl, setSubsNl] = useState<File | null>(null);

    useEffect(() => {
        !isLoading && !hasLoadedMedia && getMedia();
    }, []);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.map((file) => {
            const reader = new FileReader();

            reader.onload = function (e: ProgressEvent<FileReader>) {
                setImages((prevState: Image[]) => [
                    ...prevState,
                    { id: cuid(), src: e.target?.result as string },
                ]);
            };

            reader.readAsDataURL(file);
            setMediaUpload((prev) => [...prev, file]);
            return file;
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({ onDrop });

    const files = useMemo(() => {
        return acceptedFiles.map((file: any) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));
    }, [acceptedFiles]);

    const handleUpload = async () => {
        if (ebook) {
            await uploadEbook(mediaUpload[0]);
        } else if (wantUploadVideo) {
            if (!video || !subsEn || !subsNl) return;
            await uploadVideo({
                file: video,
                subtitles: [
                    {
                        language: "en",
                        file: subsEn,
                    },
                    {
                        language: "nl",
                        file: subsNl,
                    },
                ],
            });
        } else {
            await uploadMedia(mediaUpload);
            setImages([]);
            setMediaUpload([]);
        }
    };

    return (
        <div className="max-h-screen flex flex-col gap-4 bg-white m-4 p-4 rounded">
            <FormControlLabel
                control={
                    <Switch
                        checked={wantUploadVideo}
                        onChange={(e) => setWantUploadVideo(e.target.checked)}
                    />
                }
                label="Upload video with subtitles?"
            />
            {!wantUploadVideo ? (
                <>
                    <div
                        {...getRootProps({
                            className:
                                "dropzone text-center p-8 py-20 bg-slate-100 bg-opacity-50 border border-dashed m-auto rounded w-full",
                        })}
                    >
                        <input className="input-zone" {...getInputProps()} />
                        <div className="text-center">
                            {isDragActive ? (
                                <p className="dropzone-content">
                                    Release to drop the files here
                                </p>
                            ) : (
                                <p className="dropzone-content">
                                    Drag and drop some files here, or click to
                                    select files
                                </p>
                            )}
                            <Button
                                type="button"
                                onClick={open}
                                className="btn"
                                disabled={wantUploadVideo}
                            >
                                Click to select files
                            </Button>
                        </div>
                    </div>
                    <aside>
                        <ul>{files}</ul>
                    </aside>
                    {!modal && <ImagesGrid images={images} />}
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        onClick={() => handleUpload()}
                        loading={isLoading}
                        disabled={images.length <= 0}
                    >
                        Upload
                    </LoadingButton>
                </>
            ) : (
                <>
                    <Button variant="contained" component="label">
                        Video
                        <input
                            hidden
                            accept="video/mp4"
                            type="file"
                            onChange={(e) =>
                                setVideo(
                                    e.target.files ? e.target.files[0] : null
                                )
                            }
                        />
                    </Button>
                    <Button variant="contained" component="label">
                        English Subtitles
                        <input
                            hidden
                            accept=".vtt"
                            type="file"
                            onChange={(e) =>
                                setSubsEn(
                                    e.target.files ? e.target.files[0] : null
                                )
                            }
                        />
                    </Button>
                    <Button variant="contained" component="label">
                        Dutch Subtitles
                        <input
                            hidden
                            accept=".vtt"
                            type="file"
                            onChange={(e) =>
                                setSubsNl(
                                    e.target.files ? e.target.files[0] : null
                                )
                            }
                        />
                    </Button>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        onClick={() => handleUpload()}
                        loading={isLoading}
                        disabled={!video}
                    >
                        Upload
                    </LoadingButton>
                </>
            )}

            {isLoading ? (
                <CircularProgress />
            ) : hasError ? (
                <h2>Error.</h2>
            ) : (
                media &&
                hasLoadedMedia &&
                !ebook && (
                    <div className="grid grid-cols-5 gap-4 py-4 overflow-y-auto">
                        {media.map((img) => {
                            if (handleMediaSelect) {
                                if (
                                    img.thumbnail.toLowerCase().endsWith("mp4")
                                ) {
                                    return (
                                        <video
                                            key={img.id}
                                            src={`${process.env.REACT_APP_SERVER_URL}${img.medium}`}
                                            className={`cursor-pointer ${
                                                selectedMedia === img.id
                                                    ? "outline outline-primary p-1"
                                                    : ""
                                            }`}
                                            muted
                                            onClick={() =>
                                                handleMediaSelect(img.id)
                                            }
                                            onMouseOver={(e) =>
                                                e.currentTarget.play()
                                            }
                                            onMouseOut={(e) =>
                                                e.currentTarget.pause()
                                            }
                                        />
                                    );
                                }
                                return (
                                    <img
                                        key={img.id}
                                        src={`${process.env.REACT_APP_SERVER_URL}${img.medium}`}
                                        alt=""
                                        className={`cursor-pointer ${
                                            selectedMedia === img.id
                                                ? "outline outline-primary p-1"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            handleMediaSelect(img.id)
                                        }
                                    />
                                );
                            } else {
                                if (
                                    img.thumbnail.toLowerCase().endsWith("mp4")
                                ) {
                                    return (
                                        <video
                                            key={img.id}
                                            src={`${process.env.REACT_APP_SERVER_URL}${img.medium}`}
                                            className={`cursor-pointer ${
                                                selectedMedia === img.id
                                                    ? "outline outline-primary p-1"
                                                    : ""
                                            }`}
                                            muted
                                            onMouseOver={(e) =>
                                                e.currentTarget.play()
                                            }
                                            onMouseOut={(e) =>
                                                e.currentTarget.pause()
                                            }
                                        />
                                    );
                                }
                                return (
                                    <img
                                        key={img.id}
                                        src={`${process.env.REACT_APP_SERVER_URL}${img.medium}`}
                                        alt=""
                                        className={`cursor-pointer ${
                                            selectedMedia === img.id
                                                ? "outline outline-primary p-1"
                                                : ""
                                        }`}
                                    />
                                );
                            }
                        })}
                    </div>
                )
            )}
            {modal && setOpen && (
                <Button variant="contained" onClick={() => setOpen(false)}>
                    Close
                </Button>
            )}
        </div>
    );
};
