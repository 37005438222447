import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Nunito Sans', 
            '-apple-system', 
            'BlinkMacSystemFont', 
            'Nunito', 
            'Roboto', 
            'sans-serif'
        ].join(',')
    },
    palette: {
        // mode: 'dark',
        primary: {
            main: '#02c7f2',
            // contrastText: '#FFF'
        },
        secondary: {
            main: '#131313'
        },
        success: {
            main: '#0dff00'
        },
        text: {
            primary: '#0E0700'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                }
            }
        }
    }
})

export const darkTheme = createTheme({
    ...theme,
    palette: {
        mode: 'dark',
        primary: {
            main: '#FF8900',
            contrastText: '#FFF'
        },
        info: {
            main: '#131313'
        },
        text: {
            primary: '#F9F8F7'
        }
    }
})