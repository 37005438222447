import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageFields } from "../../../../../components";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../../../../../context";
import { CategoryInput } from "../../../../../api";

export const Category = () => {
    const { t } = useTranslation();
    const [titleEn, setTitleEn] = useState("");
    const [titleNl, setTitleNl] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionNl, setDescriptionNl] = useState("");
    let navigate = useNavigate();
    const { id } = useParams();
    const {
        isLoading,
        hasError,
        category,
        getCategory,
        createCategory,
        updateCategory,
    } = useDataContext();

    useEffect(() => {
        if (id) {
            getCategory(id);
        }
    }, [id]);

    useEffect(() => {
        setTitleEn("");
        setTitleNl("");
        setDescriptionEn("");
        setDescriptionNl("");
    }, []);

    useEffect(() => {
        if (id && category) {
            setTitleEn(category.title.en);
            setTitleNl(category.title.nl);
            setDescriptionEn(category.description?.en ?? "");
            setDescriptionNl(category.description?.nl ?? "");
        }
    }, [id, category]);

    const args: CategoryInput = {
        title: {
            en: titleEn,
            nl: titleNl,
        },
        description: {
            en: descriptionEn,
            nl: descriptionNl,
        },
        workouts: [],
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (category && id) {
            await updateCategory(category.id, args);
        } else {
            await createCategory(args);
        }
        if (!hasError || !isLoading) navigate("/categories");
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="relative pb-16 flex flex-col gap-4"
        >
            <h1 className="text-5xl text-cherny-primary">{t("Category")}</h1>

            <LanguageFields
                titleEn={titleEn}
                titleNl={titleNl}
                setTitleEn={setTitleEn}
                setTitleNl={setTitleNl}
                descriptionEn={descriptionEn}
                descriptionNl={descriptionNl}
                setDescriptionEn={setDescriptionEn}
                setDescriptionNl={setDescriptionNl}
                hasDescription
            />

            <div className="fixed bottom-0 left-0 right-0 z-[10000] p-4 bg-white shadow-mui">
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    fullWidth
                >
                    {category && id ? t("Update") : t("Create")}
                </LoadingButton>
            </div>
        </form>
    );
};
