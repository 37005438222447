import { LoadingButton } from "@mui/lab";
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationsApi } from "../../../api";
import { StyledFilledInput, StyledTextField } from "../../../components";
import { useDataContext } from "../../../context";

const SCREENS = [
    {
        title: "Workouts",
        url: "home",
    },
    {
        title: "Attachments",
        url: "attachments",
    },
    {
        title: "Ebook",
        url: "ebooks",
    },
    {
        title: "New Workout",
        url: "workout/",
    },
];

export const Notifications = () => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [screen, setScreen] = useState("");
    const [workoutId, setWorkoutId] = useState("");
    const [redirect, setRedirect] = useState(false);
    const { workouts, getWorkouts, isLoading, hasLoadedWorkouts } =
        useDataContext();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        !isLoading && !hasLoadedWorkouts && getWorkouts();
    }, []);

    const isLoadingData = useMemo(() => {
        return isLoading || isLoadingSubmit;
    }, [isLoading, isLoadingSubmit]);

    const isDisabled = useMemo(() => {
        return (
            !title ||
            !body ||
            (redirect && !screen) ||
            (screen === "workout/" && !workoutId)
        );
    }, [title, body, redirect, screen, workoutId]);

    const url = useMemo(() => {
        return screen.length > 0
            ? screen === "workout/"
                ? `workout/${workoutId}`
                : screen
            : undefined;
    }, [screen, workoutId]);

    useEffect(() => {
        setScreen("");
        setWorkoutId("");
    }, [redirect]);

    const handleSubmit = async () => {
        if (isLoadingSubmit) return;
        setIsLoadingSubmit(true);
        try {
            const { data } = await NotificationsApi.sendNotifications(
                body,
                title,
                url
            );
            if (data.success) {
                setTitle("");
                setBody("");
                setScreen("");
                setWorkoutId("");
                setRedirect(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingSubmit(false);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="relative pb-16 flex flex-col gap-4"
        >
            <FormControl fullWidth>
                <StyledTextField
                    id="title"
                    type="text"
                    name="title"
                    label="Title"
                    value={title}
                    variant="filled"
                    autoFocus
                    onChange={(e) => setTitle(e.target.value)}
                />
            </FormControl>

            <FormControl fullWidth>
                <StyledTextField
                    id="body"
                    type="text"
                    name="body"
                    label="Message"
                    value={body}
                    variant="filled"
                    multiline
                    rows={4}
                    onChange={(e) => setBody(e.target.value)}
                />
            </FormControl>

            <FormControlLabel
                control={
                    <Switch
                        value={redirect}
                        onChange={(e) => setRedirect(e.target.checked)}
                    />
                }
                label="Redirect users?"
            />

            <FormControl fullWidth variant="filled" disabled={!redirect}>
                <InputLabel>{"Redirect User"}</InputLabel>
                <Select
                    value={screen}
                    onChange={(e) => setScreen(e.target.value)}
                    input={<StyledFilledInput />}
                >
                    <MenuItem value="">{t("Select redirect page")}</MenuItem>
                    {SCREENS.map((screen) => (
                        <MenuItem key={screen.url} value={screen.url}>
                            {screen.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {screen === "workout/" && workouts && workouts.length > 0 ? (
                <FormControl
                    fullWidth
                    variant="filled"
                    disabled={!redirect || screen !== "workout/"}
                >
                    <InputLabel>{"Redirect to workout"}</InputLabel>
                    <Select
                        value={workoutId}
                        onChange={(e) => setWorkoutId(e.target.value)}
                        input={<StyledFilledInput />}
                    >
                        <MenuItem value="">{t("Select workout")}</MenuItem>
                        {workouts.map((workout) => (
                            <MenuItem key={workout.id} value={workout.id}>
                                {workout.title.en}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : null}

            <div className="fixed bottom-0 left-0 right-0 z-[10000] p-4 bg-white shadow-mui">
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoadingData}
                    disabled={isDisabled}
                    fullWidth
                >
                    {t("Send")}
                </LoadingButton>
            </div>
        </form>
    );
};
