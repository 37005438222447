import { ApiResponse, mutate, query } from "./api";
import * as T from './workouts.type'
import * as Q from './workouts.query'

interface WorkoutsApiInterface {
    getWorkouts(): ApiResponse<T.Workout[]>
    getWorkout(workoutId: string): ApiResponse<T.Workout>
    createWorkout(args: T.WorkoutInput): ApiResponse<{ success: boolean }>
    updateWorkout(id: string, args: T.WorkoutInput): ApiResponse<{ success: boolean }>
    updateFeaturedWorkout(id: string, featured: boolean): ApiResponse<{ success: boolean }>
}

export const WorkoutsApi: WorkoutsApiInterface = {
    getWorkouts() {
        return query({
            query: Q.getWorkouts,
        }).then(({ data }) => ({ data: data.workouts }))
    },
    getWorkout(workoutId) {
        return query({
            query: Q.getWorkout,
            variables: { workoutId }
        }).then(({ data }) => ({ data: data.workout }))
    },
    createWorkout(args) {
        return mutate({
            mutation: Q.createWorkout,
            variables: { args }
        }).then(({ data }) => ({ data: data.createWorkout }))
    },
    updateWorkout(id, args) {
        return mutate({
            mutation: Q.updateWorkout,
            variables: { id, args }
        }).then(({ data }) => ({ data: data.updateWorkout }))
    },
    updateFeaturedWorkout(id, featured) {
        return mutate({
            mutation: Q.updateFeaturedWorkout,
            variables: { id, featured }
        }).then(({ data }) => ({ data: data.updateFeaturedWorkout }))
    },
}