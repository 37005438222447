import { Workout } from "./workouts.type"

export interface User {
    id: string
    email: string
    token: string
    role: RoleTypes
    profile?: Profile
}

export enum RoleTypes {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface Profile {
  id: string
  firstName: string
  lastName: string
  age: Date
}

export interface UserArgs {
  email: string
  password: string
}

export interface RegisterInput {
  email: string
  password: string
  role: RoleTypes
}