import {
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    a11yProps,
    LanguageFields,
    StyledFilledInput,
    StyledTextField,
    TabPanel,
} from "../../../../../components";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDataContext } from "../../../../../context";
import { MuscleInput } from "../../../../../api";

const HiddenInput = styled("input")({
    display: "none",
});

export const Muscle = () => {
    const { t } = useTranslation();
    const [titleEn, setTitleEn] = useState("");
    const [titleNl, setTitleNl] = useState("");
    let navigate = useNavigate();
    const { id } = useParams();
    const {
        isLoading,
        hasError,
        muscle,
        getMuscle,
        createMuscle,
        updateMuscle,
    } = useDataContext();

    useEffect(() => {
        if (id) {
            getMuscle(id);
        }
    }, [id]);

    useEffect(() => {
        setTitleEn("");
        setTitleNl("");
    }, []);

    useEffect(() => {
        if (id && muscle) {
            setTitleEn(muscle.title.en);
            setTitleNl(muscle.title.nl);
        }
    }, [id, muscle]);

    const args: MuscleInput = {
        title: {
            en: titleEn,
            nl: titleNl,
        },
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (muscle && id) {
            await updateMuscle(muscle.id, args);
        } else {
            await createMuscle(args);
        }
        if (!hasError || !isLoading) navigate("/muscles");
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="relative pb-16 flex flex-col gap-4"
        >
            <h1 className="text-5xl text-cherny-primary">{t("Muscle")}</h1>

            <LanguageFields
                titleEn={titleEn}
                titleNl={titleNl}
                setTitleEn={setTitleEn}
                setTitleNl={setTitleNl}
            />

            {/* <FormControl fullWidth>
                <StyledTextField
                    id="title"
                    type="text"
                    name="title"
                    label="Title"
                    value={titleEn}
                    variant="filled"
                    autoFocus
                    onChange={(e) => setTitleEn(e.target.value)}
                />
            </FormControl> */}

            <div className="fixed bottom-0 left-0 right-0 z-[10000] p-4 bg-white shadow-mui">
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    fullWidth
                >
                    {muscle && id ? t("Update") : t("Create")}
                </LoadingButton>
            </div>
        </form>
    );
};
