import { gql } from '@apollo/client'

export const signIn = gql`
    mutation SignIn($args: UserInput!) {
        signIn(args: $args) {
            id
            email
            role
            token
        }
    }
`

export const register = gql`
    mutation Register($args: UserInput!) {
        register(args: $args) {
            id
            email
            role
            token
        }
    }
`

export const getUsers = gql`
    query Users {
        users {
            id
            email
            role
        }
    }
`