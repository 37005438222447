import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    a11yProps,
    LanguageFields,
    StyledFilledInput,
    StyledTextField,
    TabPanel,
    UploadMedia,
} from "../../../../../components";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDataContext } from "../../../../../context";
import { DealInput, DealType } from "../../../../../api";
import { Close } from "@mui/icons-material";

const HiddenInput = styled("input")({
    display: "none",
});

export const Deal = () => {
    const { t } = useTranslation();
    const [titleEn, setTitleEn] = useState("");
    const [titleNl, setTitleNl] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionNl, setDescriptionNl] = useState("");
    const [buttonEn, setButtonEn] = useState("");
    const [buttonNl, setButtonNl] = useState("");
    // const [media, setMedia] = useState<File | undefined>()
    const [open, setOpen] = useState(false);
    let navigate = useNavigate();
    const { id } = useParams();
    const {
        isLoading,
        hasError,
        deal,
        hasLoadedMedia,
        getDeal,
        getMedia,
        createDeal,
        updateDeal,
        media,
    } = useDataContext();
    const [mediaId, setMediaId] = useState("");
    const [type, setType] = useState(DealType.BROWSER);
    const [body, setBody] = useState("");
    const [subject, setSubject] = useState("");
    const [recipient, setRecipient] = useState("info@sanbo.nl");
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (!isLoading) {
            !hasLoadedMedia && getMedia();
        }
    }, []);

    const selectedMedia = useMemo(() => {
        return media?.find((med) => med.id === mediaId);
    }, [mediaId, media]);

    useEffect(() => {
        if (id) {
            getDeal(id);
        }
    }, [id]);

    useEffect(() => {
        setTitleEn("");
        setTitleNl("");
        setDescriptionEn("");
        setDescriptionNl("");
        setButtonEn("");
        setButtonNl("");
        setMediaId("");
        setType(DealType.BROWSER);
        setBody("");
        setSubject("");
        setRecipient("");
        setUrl("");
    }, []);

    useEffect(() => {
        if (id && deal) {
            setTitleEn(deal.title.en);
            setTitleNl(deal.title.nl);
            setDescriptionEn(deal.description.en);
            setDescriptionNl(deal.description.nl);
            setButtonEn(deal.button.en);
            setButtonNl(deal.button.nl);
            setMediaId(deal.media?.id ?? "");
            setType(deal.action.type);
            setBody(deal.action.options.body ?? "");
            setSubject(deal.action.options.subject ?? "");
            setRecipient(deal.action.options.recipient ?? "");
            setUrl(deal.action.options.url ?? "");
        }
    }, [id, deal]);

    const args: DealInput = {
        title: {
            en: titleEn,
            nl: titleNl,
        },
        description: {
            en: descriptionEn,
            nl: descriptionNl,
        },
        button: {
            en: buttonEn,
            nl: buttonNl,
        },
        media: mediaId,
        action: {
            type: type,
            options: {
                body,
                subject,
                recipient,
                url,
            },
        },
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (deal && id) {
            await updateDeal(deal.id, args);
        } else {
            await createDeal(args);
        }
        if (!hasError || !isLoading) navigate("/deals");
    };

    const disabled = useMemo(() => {
        return (
            !titleEn ||
            !titleNl ||
            !descriptionEn ||
            !descriptionNl ||
            !mediaId ||
            !buttonNl ||
            !buttonEn ||
            (type === DealType.BROWSER ? !url : !body || !subject || !recipient)
        );
    }, [
        descriptionEn,
        descriptionNl,
        mediaId,
        titleEn,
        titleNl,
        buttonEn,
        buttonNl,
        type,
        url,
        body,
        subject,
        recipient,
    ]);

    return (
        <form
            onSubmit={handleSubmit}
            className="relative pb-16 flex flex-col gap-4"
        >
            <h1 className="text-5xl text-cherny-primary">{t("Deal")}</h1>
            <div className="flex flex-col gap-4 p-4 rounded shadow-mui bg-white">
                <h3>{t("Media")}</h3>
                {selectedMedia && (
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}${selectedMedia.medium}`}
                        alt=""
                        className="h-32 w-[213px] object-cover mx-auto"
                    />
                )}
                <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Media
                </Button>
            </div>

            <LanguageFields
                titleEn={titleEn}
                titleNl={titleNl}
                setTitleEn={setTitleEn}
                setTitleNl={setTitleNl}
                descriptionEn={descriptionEn}
                descriptionNl={descriptionNl}
                setDescriptionEn={setDescriptionEn}
                setDescriptionNl={setDescriptionNl}
                extraEn={buttonEn}
                extraNl={buttonNl}
                setExtraEn={setButtonEn}
                setExtraNl={setButtonNl}
                hasDescription
            />

            <div className="fixed bottom-0 left-0 right-0 z-[999] p-4 bg-white shadow-mui">
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    disabled={disabled}
                    fullWidth
                >
                    {deal && id ? t("Update") : t("Create")}
                </LoadingButton>
            </div>

            <RadioGroup
                value={type}
                onChange={(e) =>
                    setType((e.target as HTMLInputElement).value as DealType)
                }
            >
                <FormControlLabel
                    value={DealType.BROWSER}
                    control={<Radio />}
                    label="Redirect to browser"
                />
                <FormControlLabel
                    value={DealType.MAIL}
                    control={<Radio />}
                    label="Redirect to mail with filled content"
                />
            </RadioGroup>
            <div className="flex flex-col gap-4">
                {type === DealType.BROWSER ? (
                    <FormControl fullWidth>
                        <StyledTextField
                            id="url"
                            name="url"
                            label="Website URL"
                            value={url}
                            variant="filled"
                            type="url"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </FormControl>
                ) : (
                    <>
                        <FormControl fullWidth>
                            <StyledTextField
                                id="subject"
                                name="subject"
                                label="Email Subject"
                                value={subject}
                                variant="filled"
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <StyledTextField
                                id="body"
                                name="body"
                                label="Email Body"
                                value={body}
                                variant="filled"
                                multiline
                                rows={6}
                                onChange={(e) => setBody(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <StyledTextField
                                id="recipient"
                                name="recipient"
                                type="email"
                                label="Email Recipient (info@sanbo.nl)"
                                value={recipient}
                                variant="filled"
                                onChange={(e) => setRecipient(e.target.value)}
                            />
                        </FormControl>
                    </>
                )}
            </div>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div className="relative">
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 16,
                        }}
                        aria-label="Sluiten"
                    >
                        <Close />
                    </IconButton>
                    <UploadMedia
                        modal
                        selectedMedia={mediaId}
                        handleMediaSelect={setMediaId}
                        setOpen={setOpen}
                    />
                </div>
            </Modal>
        </form>
    );
};
