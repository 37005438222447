import { gql } from "@apollo/client";

export const getMassages = gql`
    query Massages {
        massages {
            id
            description {
                en
                nl
            }
            time
            muscle {
                id
                title {
                    en
                    nl
                }
            }
            attachment {
                id
                title {
                    en
                    nl
                }
            }
            media {
                en {
                    id
                    thumbnail
                    medium
                    large
                }
                nl {
                    id
                    thumbnail
                    medium
                    large
                }
            }
        }
    }
`;

export const getMassage = gql`
    query Massage($id: String!) {
        massage(id: $id) {
            id
            description {
                en
                nl
            }
            time
            muscle {
                id
                title {
                    en
                    nl
                }
            }
            attachment {
                id
                title {
                    en
                    nl
                }
            }
            media {
                en {
                    id
                    thumbnail
                    medium
                    large
                }
                nl {
                    id
                    thumbnail
                    medium
                    large
                }
            }
        }
    }
`;

export const createMassage = gql`
    mutation CreateMassage($args: MassageInput!) {
        createMassage(args: $args) {
            success
        }
    }
`;

export const updateMassage = gql`
    mutation UpdateMassage($id: String!, $args: MassageInput!) {
        updateMassage(id: $id, args: $args) {
            success
        }
    }
`;
