import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    a11yProps,
    LanguageFields,
    StyledFilledInput,
    StyledTextField,
    TabPanel,
    UploadMedia,
} from "../../../../../components";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDataContext } from "../../../../../context";
import { MassageInput } from "../../../../../api";
import { Close } from "@mui/icons-material";

const HiddenInput = styled("input")({
    display: "none",
});

export const Massage = () => {
    const { t } = useTranslation();
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionNl, setDescriptionNl] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    // const [media, setMedia] = useState<File | undefined>()
    const [openEn, setOpenEn] = useState(false);
    const [openNl, setOpenNl] = useState(false);
    let navigate = useNavigate();
    const { id } = useParams();
    const {
        isLoading,
        hasError,
        massage,
        attachments,
        muscles,
        hasLoadedMedia,
        hasLoadedAttachments,
        hasLoadedMuscles,
        getMassage,
        getMedia,
        getAttachments,
        getMuscles,
        createMassage,
        updateMassage,
        media,
    } = useDataContext();
    const [mediaEnId, setMediaEnId] = useState("");
    const [mediaNlId, setMediaNlId] = useState("");
    const [attachmentId, setAttachmentId] = useState("");
    const [muscleId, setMuscleId] = useState("");

    useEffect(() => {
        if (!isLoading) {
            !hasLoadedMedia && getMedia();
            !hasLoadedAttachments && getAttachments();
            !hasLoadedMuscles && getMuscles();
        }
    }, []);

    const selectedMediaEn = useMemo(() => {
        return media?.find((med) => med.id === mediaEnId);
    }, [mediaEnId, media]);

    const selectedMediaNl = useMemo(() => {
        return media?.find((med) => med.id === mediaNlId);
    }, [mediaNlId, media]);

    useEffect(() => {
        if (id) {
            getMassage(id);
        }
    }, [id]);

    useEffect(() => {
        setDescriptionEn("");
        setDescriptionNl("");
        setMinutes(0);
        setSeconds(0);
        setMediaEnId("");
        setMediaNlId("");
        setAttachmentId("");
        setMuscleId("");
    }, []);

    useEffect(() => {
        if (id && massage) {
            setDescriptionEn(massage.description.en);
            setDescriptionNl(massage.description.nl);
            setMediaEnId(massage.media.en?.id ?? "");
            setMediaNlId(massage.media.nl?.id ?? "");
            setAttachmentId(massage.attachment.id);
            setMuscleId(massage.muscle.id);
            let mins = Math.floor(massage.time / 60);
            let secs = massage.time - mins * 60;
            setMinutes(mins);
            setSeconds(secs);
        }
    }, [id, massage]);

    const time = useMemo(() => {
        return minutes * 60 + seconds;
    }, [minutes, seconds]);

    const args: MassageInput = {
        description: {
            en: descriptionEn,
            nl: descriptionNl,
        },
        time,
        muscle: muscleId,
        attachment: attachmentId,
        mediaEn: mediaEnId,
        mediaNl: mediaNlId,
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (massage && id) {
            await updateMassage(massage.id, args);
        } else {
            await createMassage(args);
        }
        if (!hasError || !isLoading) navigate("/massages");
    };

    const disabled = useMemo(() => {
        return (
            !descriptionEn ||
            !descriptionNl ||
            !time ||
            time <= 0 ||
            !muscleId ||
            !attachmentId ||
            !mediaEnId ||
            !mediaNlId
        );
    }, [
        descriptionEn,
        descriptionNl,
        time,
        muscleId,
        attachmentId,
        mediaEnId,
        mediaNlId,
    ]);

    return (
        <form
            onSubmit={handleSubmit}
            className="relative pb-16 flex flex-col gap-4"
        >
            <h1 className="text-5xl text-cherny-primary">{t("Massage")}</h1>
            <div className="flex flex-col gap-4 p-4 rounded shadow-mui bg-white">
                <h3>{t("Media English")}</h3>
                {selectedMediaEn && (
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}${selectedMediaEn.medium}`}
                        alt=""
                        className="h-32 w-[213px] object-cover mx-auto"
                    />
                )}
                <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() => setOpenEn(true)}
                >
                    Media En
                </Button>
            </div>
            <div className="flex flex-col gap-4 p-4 rounded shadow-mui bg-white">
                <h3>{t("Media Dutch")}</h3>
                {selectedMediaNl && (
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}${selectedMediaNl.medium}`}
                        alt=""
                        className="h-32 w-[213px] object-cover mx-auto"
                    />
                )}
                <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() => setOpenNl(true)}
                >
                    Media Nl
                </Button>
            </div>

            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <FormControl fullWidth required variant="filled">
                        <InputLabel>{"Muscle"}</InputLabel>
                        <Select
                            value={muscleId}
                            onChange={(e) => setMuscleId(e.target.value)}
                            input={<StyledFilledInput />}
                        >
                            {muscles?.map((muscle) => (
                                <MenuItem key={muscle.id} value={muscle.id}>
                                    {muscle.title.en}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth required variant="filled">
                        <InputLabel>{"Attachment"}</InputLabel>
                        <Select
                            value={attachmentId}
                            onChange={(e) => setAttachmentId(e.target.value)}
                            input={<StyledFilledInput />}
                        >
                            {attachments?.map((attachment) => (
                                <MenuItem
                                    key={attachment.id}
                                    value={attachment.id}
                                >
                                    {attachment.title.en}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}

            <LanguageFields
                titleEn={descriptionEn}
                titleNl={descriptionNl}
                setTitleEn={setDescriptionEn}
                setTitleNl={setDescriptionNl}
            />

            <div className="flex gap-4">
                <FormControl fullWidth>
                    <StyledTextField
                        id="minutes"
                        type="number"
                        name="minutes"
                        label="Minutes"
                        value={minutes}
                        variant="filled"
                        inputProps={{
                            min: 0,
                        }}
                        onChange={(e) => setMinutes(Number(e.target.value))}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <StyledTextField
                        id="seconds"
                        type="number"
                        name="seconds"
                        label="Seconds"
                        value={seconds}
                        variant="filled"
                        inputProps={{
                            min: 0,
                        }}
                        onChange={(e) =>
                            setSeconds(
                                Math.max(
                                    0,
                                    Math.min(59, Number(e.target.value))
                                )
                            )
                        }
                    />
                </FormControl>
            </div>

            <div className="fixed bottom-0 left-0 right-0 z-[999] p-4 bg-white shadow-mui">
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    disabled={disabled}
                    fullWidth
                >
                    {massage && id ? t("Update") : t("Create")}
                </LoadingButton>
            </div>

            <Modal open={openEn} onClose={() => setOpenEn(false)}>
                <div className="relative">
                    <IconButton
                        onClick={() => setOpenEn(false)}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 16,
                        }}
                        aria-label="Sluiten"
                    >
                        <Close />
                    </IconButton>
                    <UploadMedia
                        modal
                        selectedMedia={mediaEnId}
                        handleMediaSelect={setMediaEnId}
                        setOpen={setOpenEn}
                    />
                </div>
            </Modal>
            <Modal open={openNl} onClose={() => setOpenNl(false)}>
                <div className="relative">
                    <IconButton
                        onClick={() => setOpenNl(false)}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 16,
                        }}
                        aria-label="Sluiten"
                    >
                        <Close />
                    </IconButton>
                    <UploadMedia
                        modal
                        selectedMedia={mediaNlId}
                        handleMediaSelect={setMediaNlId}
                        setOpen={setOpenNl}
                    />
                </div>
            </Modal>
        </form>
    );
};
