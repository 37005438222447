import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const NotFound = () => {
    let navigate = useNavigate()

    return (
        <section className='flex flex-col gap-4 items-start'>
            <h1 className='text-5xl'>404 - Not Found</h1>
            <Button variant='contained' onClick={() => navigate('/')}>
                Home
            </Button>
        </section>
    )
}
