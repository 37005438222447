import { gql } from '@apollo/client'

export const getCategories = gql`
    query Categories {
        categories {
            id
            title{
                en
                nl
            }
            description{
                en
                nl
            }
            workouts {
                id
                title{
                    en
                    nl
                }
                massages {
                    id
                    description{
                        en
                        nl
                    }
                    time
                }
            }
        }
    }
`

export const getCategory = gql`
    query Category($id: String!) {
        category(id: $id) {
            id
            title{
                en
                nl
            }
            description{
                en
                nl
            }
            workouts {
                id
                title{
                    en
                    nl
                }
                massages {
                    id
                    description{
                        en
                        nl
                    }
                    time
                }
            }
        }
    }
`

export const createCategory = gql`
    mutation CreateCategory($args: CategoryInput!) {
        createCategory(args: $args) {
            success
        }
    }
`

export const updateCategory = gql`
    mutation UpdateCategory($id: String!, $args: CategoryInput!) {
        updateCategory(id: $id, args: $args) {
            success
        }
    }
`