import { gql } from "@apollo/client";

export const getMedia = gql`
    query Media {
        media {
            id
            thumbnail
            medium
            large
        }
    }
`

export const getEbook = gql`
    query Ebook {
        ebook {
            id
            thumbnail
            medium
            large
        }
    }
`

export const uploadMedia = gql`
    mutation UploadMedia($files: [Upload!]!) {
        uploadMedia(files: $files) {
            id
            thumbnail
            medium
            large
        }
    }
`

export const uploadEbook = gql`
    mutation UploadEbook($file: Upload!) {
        uploadEbook(file: $file) {
            id
            thumbnail
            medium
            large
        }
    }
`

export const uploadVideo = gql`
    mutation UploadVideo($video: Video!) {
        uploadVideo(video: $video) {
            id
            thumbnail
            medium
            large
        }
    }
`