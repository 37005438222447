import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const Layout = () => {
  let location = useLocation()

  return (
    <main className={`min-h-screen h-screen relative ${location.pathname === '/sign-in' ? 'grid place-content-center': 'bg-gray-50'}`}>
        <Outlet />
    </main>
  )
}
