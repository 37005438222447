import { GridOverlay } from '@mui/x-data-grid'
import { LinearProgress } from '@mui/material'

export const LoadingOverlay = () => {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
                <LinearProgress color="secondary" />
            </div>
        </GridOverlay>
    );
}