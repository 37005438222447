import { Button, FilledInput, styled, TextField } from '@mui/material'
import React from 'react'

export const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px',
        '&:before': {
            borderBottom: 'none',
        },
        '&.Mui-disabled:before': {
            borderBottomStyle: 'none',
        },
        '&:focus, &:hover, &.Mui-focused': {
            backgroundColor: theme.palette.mode === 'light' ? 'rgba(225, 225, 225, 0.97)' : '#000',
        },
    }
}))

export const StyledFilledInput = styled(FilledInput)(({ theme }) => ({
    '&.MuiFilledInput-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px',
        '&:before': {
            borderBottom: 'none',
        },
        '&:focus, &:hover, &.Mui-focused': {
            backgroundColor: theme.palette.mode === 'light' ? 'rgba(225, 225, 225, 0.97)' : 'rgba(0, 0, 0, 0.15)',
        }
    }
}))

export const SquareTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderRadius: 0,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
        // '& fieldset': {
        //     borderColor: `${theme.palette.info.main}80`
        // },
        '&:before': {
            borderBottom: 'none',
        },
        '&:focus, &:hover, &.Mui-focused': {
            backgroundColor: theme.palette.mode === 'light' ? 'rgba(225, 225, 225, 0.97)' : '#000',
        },
        '& input': {
            textAlign: 'center'
        },
    }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(225, 225, 225, 0.97)'
        }
    }
}))