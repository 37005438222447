import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItemButton } from "@mui/material";
import {
    Accessibility,
    Attachment,
    LocalOffer,
    Category,
    FitnessCenter,
    MenuBook,
    Notifications,
    PermMedia,
    Spa,
} from "@mui/icons-material";

export const AdminList = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let location = useLocation();
    const isDisabled = false;
    const { pathname } = location;

    return (
        <List>
            <ListItemButton
                onClick={() => navigate("/")}
                disabled={isDisabled}
                selected={pathname === "/"}
            >
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItemButton>

            <Divider />

            <ListItemButton
                onClick={() => navigate("/workouts")}
                disabled={isDisabled}
                selected={pathname === "/workouts"}
            >
                <ListItemIcon>
                    <FitnessCenter />
                </ListItemIcon>
                <ListItemText primary="Workouts" />
            </ListItemButton>
            <ListItemButton
                onClick={() => navigate("/deals")}
                disabled={isDisabled}
                selected={pathname === "/deals"}
            >
                <ListItemIcon>
                    <LocalOffer />
                </ListItemIcon>
                <ListItemText primary="Deals" />
            </ListItemButton>
            <ListItemButton
                onClick={() => navigate("/users")}
                disabled={isDisabled}
                selected={pathname === "/users"}
            >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>

            <Divider />

            <ListItemButton
                onClick={() => navigate("/categories")}
                disabled={isDisabled}
                selected={pathname === "/categories"}
            >
                <ListItemIcon>
                    <Category />
                </ListItemIcon>
                <ListItemText primary="Categories" />
            </ListItemButton>
            <ListItemButton
                onClick={() => navigate("/massages")}
                disabled={isDisabled}
                selected={pathname === "/massages"}
            >
                <ListItemIcon>
                    <Spa />
                </ListItemIcon>
                <ListItemText primary="Massages" />
            </ListItemButton>
            <ListItemButton
                onClick={() => navigate("/muscles")}
                disabled={isDisabled}
                selected={pathname === "/muscles"}
            >
                <ListItemIcon>
                    <Accessibility />
                </ListItemIcon>
                <ListItemText primary="Muscles" />
            </ListItemButton>
            <ListItemButton
                onClick={() => navigate("/attachments")}
                disabled={isDisabled}
                selected={pathname === "/attachments"}
            >
                <ListItemIcon>
                    <Attachment />
                </ListItemIcon>
                <ListItemText primary="Attachments" />
            </ListItemButton>

            <Divider />

            <ListItemButton
                onClick={() => navigate("/media")}
                disabled={isDisabled}
                selected={pathname === "/media"}
            >
                <ListItemIcon>
                    <PermMedia />
                </ListItemIcon>
                <ListItemText primary="Media" />
            </ListItemButton>

            <ListItemButton
                onClick={() => navigate("/ebook")}
                disabled={isDisabled}
                selected={pathname === "/media"}
            >
                <ListItemIcon>
                    <MenuBook />
                </ListItemIcon>
                <ListItemText primary="Ebook" />
            </ListItemButton>

            <ListItemButton
                onClick={() => navigate("/notifications")}
                disabled={isDisabled}
                selected={pathname === "/media"}
            >
                <ListItemIcon>
                    <Notifications />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
            </ListItemButton>
        </List>
    );
};
