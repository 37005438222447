import { ApolloClient, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from "graphql-ws";
import { User } from "../api";

const user: User = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user') || '{}') : undefined
const token = user?.token

const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_SERVER_URL}/v1`,
    headers: {
        authorization: token ? `Bearer ${token}` : '',
        'Apollo-Require-Preflight': 'true',
    }
})

const wsLink = new GraphQLWsLink(
    createClient({
        url: `${process.env.REACT_APP_SUBSCRIPTION_URL}/v1`
    })
)

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    uploadLink,
)

export const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
})