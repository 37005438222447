import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DataGridPro, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid-pro'
// import { DataGrid, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { CircularProgress, Fab, FormControl, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import { Add } from '@mui/icons-material'
import { LoadingOverlay, UploadMedia } from '../../../components'
import { useDataContext } from '../../../context'

export const Ebook = () => {
    const { isLoading, hasLoadedEbook, hasError, ebook, getEbook } = useDataContext()
    const { t, i18n } = useTranslation()

    useEffect(() => {
        !isLoading && !hasLoadedEbook && getEbook()
    }, [])

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : hasError ? (
                <h2>Error.</h2>
            ) : hasLoadedEbook && (
                <div className='pb-16 flex flex-col gap-4'>
                    <h1 className='text-5xl text-cherny-primary'>{t('Ebook')}</h1>
                    <UploadMedia ebook />
                    {ebook && (
                        <div className='h-140 w-full'>
                            <iframe className='w-full h-full' src={`${process.env.REACT_APP_SERVER_URL}${ebook.large}`} />
                        </div>
                    )}
                </div>
            )}
        </>
    )
}