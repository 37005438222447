import { gql } from '@apollo/client'

export const getAttachments = gql`
    query Attachments {
        attachments {
            id
            title{
                en
                nl
            }
            description{
                en
                nl
            }
            media {
                id
                thumbnail
                medium
                large
            }
        }
    }
`

export const getAttachment = gql`
    query Attachment($id: String!) {
        attachment(id: $id) {
            id
            title{
                en
                nl
            }
            description{
                en
                nl
            }
            media {
                id
                thumbnail
                medium
                large
            }
        }
    }
`

export const createAttachment = gql`
    mutation CreateAttachment($args: AttachmentInput!) {
        createAttachment(args: $args) {
            success
        }
    }
`

export const updateAttachment = gql`
    mutation UpdateAttachment($id: String!, $args: AttachmentInput!) {
        updateAttachment(id: $id, args: $args) {
            success
        }
    }
`