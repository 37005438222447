import { Check, Error } from "@mui/icons-material";
import { Tab, Tabs, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTextField } from "../StyledElements";
import { a11yProps, TabPanel } from "./TabPanel";

interface LanguageTitleFieldsProps {
    titleEn: string;
    titleNl: string;
    descriptionEn?: string;
    descriptionNl?: string;
    extraEn?: string;
    extraNl?: string;
    setTitleEn: (value: string) => void;
    setTitleNl: (value: string) => void;
    setDescriptionEn?: (value: string) => void;
    setDescriptionNl?: (value: string) => void;
    setExtraEn?: (value: string) => void;
    setExtraNl?: (value: string) => void;
    hasDescription?: false;
}

interface LanguageDescriptionFieldsProps {
    titleEn: string;
    titleNl: string;
    descriptionEn: string;
    descriptionNl: string;
    extraEn?: string;
    extraNl?: string;
    setTitleEn: (value: string) => void;
    setTitleNl: (value: string) => void;
    setDescriptionEn: (value: string) => void;
    setDescriptionNl: (value: string) => void;
    setExtraEn?: (value: string) => void;
    setExtraNl?: (value: string) => void;
    hasDescription?: true;
}

export const LanguageFields = ({
    titleEn,
    titleNl,
    descriptionEn,
    descriptionNl,
    extraEn,
    extraNl,
    setTitleEn,
    setTitleNl,
    setDescriptionEn,
    setDescriptionNl,
    setExtraEn,
    setExtraNl,
    hasDescription = false,
}: LanguageTitleFieldsProps | LanguageDescriptionFieldsProps) => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(0);

    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <>
            <Tabs
                value={tab}
                onChange={handleChange}
                textColor="inherit"
                aria-label="Change Language"
                variant="fullWidth"
            >
                <Tab
                    icon={
                        titleEn ? (
                            <Check color="secondary" />
                        ) : (
                            <Error color="warning" />
                        )
                    }
                    iconPosition="end"
                    label={t("English")}
                    {...a11yProps(0)}
                />
                <Tab
                    icon={
                        titleNl ? (
                            <Check color="secondary" />
                        ) : (
                            <Error color="warning" />
                        )
                    }
                    iconPosition="end"
                    label={t("Dutch")}
                    {...a11yProps(1)}
                />
            </Tabs>

            <TabPanel value={tab} index={0}>
                <div className="flex flex-col gap-4">
                    <StyledTextField
                        fullWidth
                        required
                        label={t("TitleEn")}
                        variant="filled"
                        value={titleEn}
                        onChange={(e) => setTitleEn(e.target.value)}
                        error={!titleEn}
                        helperText={!titleEn ? t("FillIn") : ""}
                    />

                    {hasDescription && setDescriptionEn && (
                        <StyledTextField
                            fullWidth
                            multiline
                            rows={6}
                            label={t("DescriptionEn")}
                            variant="filled"
                            value={descriptionEn}
                            onChange={(e) => setDescriptionEn(e.target.value)}
                        />
                    )}
                    {setExtraEn && (
                        <StyledTextField
                            fullWidth
                            multiline
                            label={t("Button En")}
                            variant="filled"
                            value={extraEn}
                            onChange={(e) => setExtraEn(e.target.value)}
                        />
                    )}
                </div>
            </TabPanel>

            <TabPanel value={tab} index={1}>
                <div className="flex flex-col gap-4">
                    <StyledTextField
                        fullWidth
                        required
                        label={t("TitleNl")}
                        variant="filled"
                        value={titleNl}
                        onChange={(e) => setTitleNl(e.target.value)}
                        error={!titleNl}
                        helperText={!titleNl ? t("FillIn") : ""}
                    />

                    {hasDescription && setDescriptionNl && (
                        <StyledTextField
                            fullWidth
                            multiline
                            rows={6}
                            label={t("DescriptionNl")}
                            variant="filled"
                            value={descriptionNl}
                            onChange={(e) => setDescriptionNl(e.target.value)}
                        />
                    )}
                    {setExtraNl && (
                        <StyledTextField
                            fullWidth
                            multiline
                            label={t("Button Nl")}
                            variant="filled"
                            value={extraNl}
                            onChange={(e) => setExtraNl(e.target.value)}
                        />
                    )}
                </div>
            </TabPanel>
        </>
    );
};
