import { LoadingButton } from '@mui/lab';
import { FormControl, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useAuthContext } from '../../../context';
import { StyledTextField } from '../../../components';
import { Navigate } from 'react-router-dom';

export const SignIn = () => {
    const { isLoading, signIn, user } = useAuthContext()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleSignIn = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        signIn(email, password)
    }

    if (user && user.token) return (
        <Navigate to="/" replace />
    )

    return (
        <form onSubmit={handleSignIn} className="flex flex-col gap-4 p-8" style={{ width: 'clamp(20rem, 33vw, 50rem)' }}>
            <h1 className={`flex items-center justify-center relative h-40 w-full -mt-4 mb-4`}>
                <img 
                    src='https://cdn.shopify.com/s/files/1/0460/8117/9813/files/Logowit_blauwkopie_medium.png?v=1625039800'
                    alt="" 
                    className="absolute object-contain min-w-full max-w-full min-h-full max-h-full inset-0"  
                />
            </h1>
            <FormControl fullWidth>
                <StyledTextField
                    id="email"
                    type="email"
                    name="email"
                    label="E-Mail"
                    value={email}
                    variant='filled'
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormControl>
            <FormControl fullWidth>
                <StyledTextField
                    id="password"
                    type="password"
                    name="password"
                    label="Password"
                    value={password}
                    variant='filled'
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormControl>
            <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isLoading}
                disabled={!email || !password}
                fullWidth
            >
                Sign In
            </LoadingButton>
        </form>
    )
}
