import { client } from '../utils/apollo'
import { ApiResponse, mutate, query } from './api'
import * as T from './attachments.type'
import * as Q from './attachments.query'

interface AttachmentsApiInterface {
    getAttachments(): ApiResponse<T.Attachment[]>
    getAttachment(id: string): ApiResponse<T.Attachment>
    createAttachment(args: T.AttachmentInput): ApiResponse<{ success: boolean }>
    updateAttachment(id: string, args: T.AttachmentInput): ApiResponse<{ success: boolean }>
}

export const AttachmentsApi: AttachmentsApiInterface = {
    getAttachments() {
        return query({
            query: Q.getAttachments
        }).then(({ data }) => ({ data: data.attachments }))
    },
    getAttachment(id) {
        return query({
            query: Q.getAttachment,
            variables: { id }
        }).then(({ data }) => ({ data: data.attachment }))
    },
    createAttachment(args) {
        return mutate({
            mutation: Q.createAttachment,
            variables: { args }
        }).then(({ data }) => ({ data: data.createAttachment }))
    },
    updateAttachment(id, args) {
        return mutate({
            mutation: Q.updateAttachment,
            variables: { id, args }
        }).then(({ data }) => ({ data: data.updateAttachment }))
    },
}