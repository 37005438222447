import { ApiResponse, query } from "./api";
import * as T from './dashboard.type'
import * as Q from './dashboard.query'

interface DashboardApiInterface {
    getDashboard(userId: string): ApiResponse<T.Dashboard>
}

export const DashboardApi: DashboardApiInterface = {
    getDashboard(userId) {
        return query({
            query: Q.getDashboard,
            variables: { userId }
        }).then(({ data }) => ({ data }))
    },
}