import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthLayout, Layout, RequireAdmin } from "../components";
import { DataProvider } from "../context";
import {
    Workouts,
    Dashboard,
    Categories,
    Muscles,
    Users,
    Attachments,
    Media,
    Massages,
    Ebook,
    Notifications,
} from "./admin";
import { Attachment } from "./admin/Attachments/pages";
import { Category } from "./admin/Categories/pages";
import { Deals } from "./admin/Deals";
import { Deal } from "./admin/Deals/pages";
import { Massage } from "./admin/Massages/pages";
import { Muscle } from "./admin/Muscles/pages";
import { Workout } from "./admin/WorkoutsRoutes/pages";
import { SignIn } from "./auth";
import { NotFound } from "./NotFound";

export const AdminRoutes = () => {
    return (
        <DataProvider>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/sign-in" element={<SignIn />} />

                    <Route path="/" element={<AuthLayout />}>
                        <Route
                            index
                            element={
                                <RequireAdmin>
                                    <Dashboard />
                                </RequireAdmin>
                            }
                        />

                        <Route path="users">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Users />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="workouts">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Workouts />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <RequireAdmin>
                                        <Workout />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAdmin>
                                        <Workout />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="deals">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Deals />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <RequireAdmin>
                                        <Deal />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAdmin>
                                        <Deal />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="categories">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Categories />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <RequireAdmin>
                                        <Category />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAdmin>
                                        <Category />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="muscles">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Muscles />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <RequireAdmin>
                                        <Muscle />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAdmin>
                                        <Muscle />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="attachments">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Attachments />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <RequireAdmin>
                                        <Attachment />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAdmin>
                                        <Attachment />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="massages">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Massages />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="add"
                                element={
                                    <RequireAdmin>
                                        <Massage />
                                    </RequireAdmin>
                                }
                            />
                            <Route
                                path="edit/:id"
                                element={
                                    <RequireAdmin>
                                        <Massage />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="media">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Media />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="notifications">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Notifications />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="ebook">
                            <Route
                                index
                                element={
                                    <RequireAdmin>
                                        <Ebook />
                                    </RequireAdmin>
                                }
                            />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Routes>
        </DataProvider>
    );
};
