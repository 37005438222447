import { Media } from "./media.type";
import { Language } from "./types";

export interface Deal {
    id: string;
    title: Language;
    description: Language;
    button: Language;
    media?: Media
    action: {
        type: DealType;
        options: {
            body?: string;
            subject?: string;
            recipient?: string;
            url?: string;
        }
    }
}

export enum DealType {
    MAIL = 'MAIL',
    BROWSER = 'BROWSER'
}

export interface DealInput {
    title: Language;
    description: Language;
    button: Language;
    media: string
    action: {
        type: DealType;
        options: {
            body?: string;
            subject?: string;
            recipient?: string;
            url?: string;
        }
    }
}