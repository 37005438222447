import { createContext, useContext, useState } from "react";
import { useLocalstorageState } from "rooks";
import { RegisterInput, RoleTypes, User, UsersApi } from "../api";

interface AuthContextInterface {
    user: User | undefined
    isLoading: boolean
    hasError: boolean
    signIn: (email: string, password: string) => Promise<void>
    register: (args: RegisterInput) => Promise<void>
    signOut: () => void
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined)

const useAuthContext = () => useContext(AuthContext) as AuthContextInterface

const initialUser: User = {
    id: '',
    email: '',
    token: '',
    role: RoleTypes.ADMIN,
}

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useLocalstorageState<User | undefined>('user', initialUser)
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false)

    const signIn = async (email: string, password: string) => {
        if (isLoading) return
        setIsLoading(true)
        setHasError(false)
        try {
            const { data } = await UsersApi.signIn({ email, password })
            setUser(data)
        } catch (error) {
            console.log(error)
            setHasError(true)
        } finally {
            setIsLoading(false)
        }
    }

    const register = async (args: RegisterInput) => {
        if (isLoading) return
        setIsLoading(true)
        setHasError(false)
        try {
            const { data } = await UsersApi.register(args)
            setUser(data)
        } catch (error) {
            console.log(error)
            setHasError(true)
        } finally {
            setIsLoading(false)
        }

    }

    const signOut = () => {
        setUser(initialUser)
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoading,
                hasError,
                signIn,
                register,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, useAuthContext, AuthProvider }