import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadMedia } from '../../../components'
import { useDataContext } from '../../../context'

export const Media = () => {
    const { t } = useTranslation()

    return (
        <>
            <h1 className='text-5xl text-cherny-primary'>{t('Media')}</h1>
            <UploadMedia />
        </>
    )
}
