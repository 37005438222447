import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../header'

export const AuthLayout = () => {
  return (
    <Header>
      <Outlet />
    </Header>
  )
}
