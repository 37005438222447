import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client';
import { client, theme } from './utils';
import { ThemeProvider } from '@mui/material';
import { AuthProvider } from './context';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enI18n from './i18n/en.json'
import nlI18n from './i18n/nl.json'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE || '')

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enI18n
      },
      nl: {
        translation: nlI18n
      },
    },
    lng: "nl",
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false
    }
  })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </ApolloProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
