import React, { useEffect, useMemo, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
)

const isLoading = false
const DATA: any[] = []

export const Dashboard = () => {
    const initialWeeks = 2
    const [weeks, setWeeks] = useState(initialWeeks)
    const { t } = useTranslation()
    const [showNumbers, setShowNumbers] = useState(false)

    let getDaysArray = function(s: number, e: Date) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
    
    let dayList = useMemo(() => {
        const now = new Date()
        let newDate = new Date(now.getTime() - (weeks - initialWeeks) * 7 * 24 * 60 * 60 * 1000)
        return getDaysArray(newDate.setDate(newDate.getDate() - (initialWeeks * 7)), new Date(now.getTime() - (weeks - initialWeeks) * 7 * 24 * 60 * 60 * 1000))
    }, [weeks])
    
    const data = useMemo(() => {
        return {
            labels: dayList.map((v) => v.toISOString().slice(0, 10)),
            datasets: [
                {
                    label: 'Orders',
                    data: dayList.map(day => {
                        let total = 0
                        DATA?.map(order => {
                            const isoDay = day.toISOString().slice(0, 10)
                            const orderDate = new Date(order.createdAt).toISOString().slice(0, 10)
                            if (isoDay === orderDate) {
                                total += 0
                            }
                        })
                        return total
                    }),
                    backgroundColor: '#009175'
                }
            ]
        }
    }, [DATA, dayList])

    return (
        isLoading ? (
            <CircularProgress />
        ) : (
            <div className='pb-16'>
                <h1 className='text-5xl'>Welcome</h1>
                {/* <div className='p-6 bg-white rounded shadow-mui flex flex-col gap-4'>
                    <Bar 
                        options={{
                            responsive: true,
                            scales: {
                            y: {
                                ticks: {
                                    callback: function(value: string | number) {
                                        return '€' + value;
                                    }
                                }
                            }
                            }
                        }} 
                        data={data} 
                    />
                    <div className='flex flex-col sm:flex-row items-start sm:justify-between'>
                        <Button onClick={() => setWeeks(weeks => weeks + initialWeeks)}>
                            {t('Prev')}
                        </Button>
                        <div className='flex gap-2'>
                        <Button onClick={() => setWeeks(weeks => weeks - initialWeeks)} disabled={weeks <= initialWeeks}>
                            {t('Next')}
                        </Button>
                        <Button onClick={() => setWeeks(initialWeeks)} disabled={weeks <= initialWeeks}>
                            {t('Today')}
                        </Button>
                    </div>
                    </div>
                </div> */}
            </div>
        )
    )
}
