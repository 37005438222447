import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    a11yProps,
    LanguageFields,
    StyledFilledInput,
    StyledTextField,
    TabPanel,
    UploadMedia,
} from "../../../../../components";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../../../../../context";
import { WorkoutInput } from "../../../../../api";
import {
    ChevronLeft,
    Close,
    FavoriteBorder,
    PlayArrow,
} from "@mui/icons-material";
import { ReactSortable } from "react-sortablejs";
import cuid from "cuid";

const HiddenInput = styled("input")({
    display: "none",
});

export const Workout = () => {
    const { t } = useTranslation();
    const [titleEn, setTitleEn] = useState("");
    const [titleNl, setTitleNl] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionNl, setDescriptionNl] = useState("");
    const [open, setOpen] = useState(false);
    let navigate = useNavigate();
    const { id } = useParams();
    const {
        isLoading,
        hasError,
        workout,
        massages,
        categories,
        hasLoadedMedia,
        hasLoadedCategories,
        hasLoadedMassages,
        getWorkout,
        getMedia,
        getCategories,
        getMassages,
        createWorkout,
        updateWorkout,
        media,
    } = useDataContext();
    const [mediaId, setMediaId] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [massageIds, setMassageIds] = useState<
        { id: string; order: number }[]
    >([{ id: "", order: 0 }]);

    const appWidth = useRef<HTMLDivElement>(null);

    const imgHeight = useMemo(() => {
        return ((appWidth.current ? appWidth.current.offsetWidth : 0) / 5) * 3;
    }, [appWidth.current]);

    const handleMassageIds = (e: SelectChangeEvent, index: number) => {
        let array = [...massageIds];
        array[index].id = e.target.value;
        setMassageIds(array);
    };

    const selectedMassages = useMemo(() => {
        if (!massages) return [];
        return (
            massageIds.map((massageId) => {
                return massages.find((massage) => massage.id === massageId.id);
            }) ?? []
        );
    }, [massages, massageIds]);

    const totalTime = useMemo(() => {
        let time = 0;
        massageIds.map((massageId) => {
            time +=
                massages?.find((massage) => massage.id === massageId.id)
                    ?.time ?? 0;
        });
        return time;
    }, [massages, massageIds]);

    useEffect(() => {
        if (!isLoading) {
            !hasLoadedMedia && getMedia();
            !hasLoadedCategories && getCategories();
            !hasLoadedMassages && getMassages();
        }
    }, []);

    const selectedMedia = useMemo(() => {
        return media?.find((med) => med.id === mediaId);
    }, [mediaId, media]);

    useEffect(() => {
        if (id) {
            getWorkout(id);
        }
    }, [id]);

    useEffect(() => {
        setTitleEn("");
        setTitleNl("");
        setDescriptionEn("");
        setDescriptionNl("");
        setMediaId("");
        setCategoryId("");
        setMassageIds([{ id: "", order: 0 }]);
    }, []);

    useEffect(() => {
        if (id && workout) {
            setTitleEn(workout.title.en);
            setTitleNl(workout.title.nl);
            setDescriptionEn(workout.description.en);
            setDescriptionNl(workout.description.nl);
            setCategoryId(workout.category?.id ?? "");
            setMediaId(workout.media?.id ?? "");
            setMassageIds(
                workout.massages.map((massage, index) => ({
                    id: massage.id,
                    order: index,
                }))
            );
        }
    }, [id, workout]);

    const args: WorkoutInput = {
        title: {
            en: titleEn,
            nl: titleNl,
        },
        description: {
            en: descriptionEn,
            nl: descriptionNl,
        },
        category: categoryId,
        massages: massageIds.map((massageId) => massageId.id),
        media: mediaId,
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (workout && id) {
            await updateWorkout(workout.id, args);
        } else {
            await createWorkout(args);
        }
        if (!hasError || !isLoading) navigate("/workouts");
    };

    const disabled = useMemo(() => {
        let massageDisabled = false;
        massageIds.map((massageId) => {
            if (!massageId.id) {
                massageDisabled = true;
            }
        });
        return (
            !descriptionEn ||
            !descriptionNl ||
            !mediaId ||
            !titleEn ||
            !titleNl ||
            !categoryId ||
            massageDisabled
        );
    }, [
        descriptionEn,
        descriptionNl,
        mediaId,
        titleEn,
        titleNl,
        categoryId,
        massageIds,
    ]);

    return (
        <div className="flex items-start pb-20">
            <form
                onSubmit={handleSubmit}
                className="relative mb-8 flex flex-col justify-between gap-6 h-full flex-1"
            >
                <h1 className="text-5xl text-cherny-primary">{t("Workout")}</h1>
                <div className="flex flex-col gap-6 pb-10 px-2 flex-1 overflow-y-auto">
                    <div className="flex flex-col gap-4 p-4 rounded shadow-mui bg-white">
                        <h3>{t("Cover Media (ratio 5:3)")}</h3>
                        {selectedMedia && (
                            <img
                                src={`${process.env.REACT_APP_SERVER_URL}${selectedMedia.medium}`}
                                alt=""
                                className="h-32 w-[213px] object-cover mx-auto"
                            />
                        )}
                        <Button
                            variant="contained"
                            fullWidth
                            color="secondary"
                            onClick={() => setOpen(true)}
                        >
                            Media
                        </Button>
                    </div>

                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <FormControl fullWidth required variant="filled">
                                <InputLabel>{"Category"}</InputLabel>
                                <Select
                                    value={categoryId}
                                    onChange={(e) =>
                                        setCategoryId(e.target.value)
                                    }
                                    input={<StyledFilledInput />}
                                >
                                    {categories?.map((category) => (
                                        <MenuItem
                                            key={category.id}
                                            value={category.id}
                                        >
                                            {category.title.en}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="flex flex-col gap-4 p-4 rounded shadow-mui bg-white">
                                <h3>{t("Massages")}</h3>
                                <ReactSortable
                                    list={massageIds}
                                    setList={setMassageIds}
                                    className="flex flex-col gap-2"
                                >
                                    {massageIds.map((massageId, index) => (
                                        <FormControl
                                            key={massageId.order}
                                            fullWidth
                                            required
                                            variant="filled"
                                        >
                                            <InputLabel>{"Massage"}</InputLabel>
                                            <Select
                                                value={massageId.id}
                                                onChange={(e) =>
                                                    handleMassageIds(e, index)
                                                }
                                            >
                                                {massages?.map((massage) => (
                                                    <MenuItem
                                                        key={massage.id}
                                                        value={massage.id}
                                                    >
                                                        <p className="flex gap-2 justify-between w-full">
                                                            <span>
                                                                {
                                                                    massage
                                                                        .description
                                                                        .en
                                                                }
                                                            </span>
                                                            <span>
                                                                {new Date(
                                                                    massage.time *
                                                                        1000
                                                                )
                                                                    .toISOString()
                                                                    .slice(
                                                                        14,
                                                                        19
                                                                    )}
                                                            </span>
                                                        </p>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ))}
                                </ReactSortable>
                                <Button
                                    onClick={() =>
                                        setMassageIds([
                                            ...massageIds,
                                            {
                                                id: "",
                                                order: massageIds.length,
                                            },
                                        ])
                                    }
                                    disabled={
                                        !massageIds[massageIds.length - 1].id
                                    }
                                >
                                    Add
                                </Button>
                            </div>
                        </>
                    )}

                    <LanguageFields
                        titleEn={titleEn}
                        titleNl={titleNl}
                        setTitleEn={setTitleEn}
                        setTitleNl={setTitleNl}
                        descriptionEn={descriptionEn}
                        descriptionNl={descriptionNl}
                        setDescriptionEn={setDescriptionEn}
                        setDescriptionNl={setDescriptionNl}
                        hasDescription
                    />

                    {/* <FormControl fullWidth>
                        <StyledTextField
                            id="title"
                            type="text"
                            name="title"
                            label="Title"
                            value={titleEn}
                            variant="filled"
                            autoFocus
                            onChange={(e) => setTitleEn(e.target.value)}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <StyledTextField
                            id="description"
                            type="text"
                            name="description"
                            label="Description"
                            value={descriptionEn}
                            variant="filled"
                            onChange={(e) => setDescriptionEn(e.target.value)}
                        />
                    </FormControl> */}
                </div>

                <div className="fixed bottom-0 left-0 right-0 z-[999] p-4 bg-white shadow-mui">
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={isLoading}
                        disabled={disabled}
                        fullWidth
                    >
                        {workout && id ? t("Update") : t("Create")}
                    </LoadingButton>
                </div>

                <Modal open={open} onClose={() => setOpen(false)}>
                    <div className="relative">
                        <IconButton
                            onClick={() => setOpen(false)}
                            sx={{
                                position: "absolute",
                                top: 8,
                                right: 16,
                            }}
                            aria-label="Sluiten"
                        >
                            <Close />
                        </IconButton>
                        <UploadMedia
                            modal
                            selectedMedia={mediaId}
                            handleMediaSelect={setMediaId}
                            setOpen={setOpen}
                        />
                    </div>
                </Modal>
            </form>

            <div className="min-w-[350px] p-8">
                <div className="shadow-mui bg-white rounded-3xl flex flex-col justify-between h-[580px]">
                    <nav className="px-4 py-1 pt-6 flex gap-3 justify-between items-center">
                        <ChevronLeft color="primary" />
                        <p className="flex gap-1 flex-1">
                            <span className="font-black">SANBO</span>
                            <span className="font-bold italic">
                                {titleEn ? titleEn : "Title"}
                            </span>
                        </p>
                        <FavoriteBorder color="primary" />
                    </nav>
                    <div
                        ref={appWidth}
                        className="relative w-full"
                        style={{ height: imgHeight, minHeight: imgHeight }}
                    >
                        {selectedMedia ? (
                            <img
                                src={`${process.env.REACT_APP_SERVER_URL}${selectedMedia.medium}`}
                                alt=""
                                className="absolute object-cover min-w-full max-w-full min-h-full max-h-full inset-0"
                            />
                        ) : (
                            <img
                                alt=""
                                src="https://i.shgcdn.com/2808656b-86b0-44f9-8497-86d4f1b627c4/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
                                className="absolute object-cover min-w-full max-w-full min-h-full max-h-full inset-0"
                            />
                        )}
                        <div className="absolute bottom-0 px-2 py-1 bg-black bg-opacity-50 left-0 right-0">
                            <p className="text-white font-bold text-sm">
                                {titleEn ? titleEn : "Title"}
                            </p>
                            <p className="text-white text-xs">
                                {descriptionEn ? descriptionEn : "Description"}
                            </p>
                        </div>
                    </div>
                    <div className="flex-1 bg-slate-100 overflow-y-auto">
                        {selectedMassages.map((massage) => {
                            if (massage)
                                return (
                                    <p
                                        key={massage.id}
                                        className="flex justify-between px-1 py-2 border-b border-white text-sm"
                                    >
                                        <span>{`${massage.muscle.title.en} - ${massage.description.en}`}</span>
                                        <span>
                                            {new Date(massage.time * 1000)
                                                .toISOString()
                                                .slice(14, 19)}
                                        </span>
                                    </p>
                                );
                        })}
                    </div>
                    <div className="p-2 flex flex-col gap-2 items-center justify-center">
                        <div className="border-[12px] border-primary rounded-full">
                            <div className="bg-primary w-28 h-28 rounded-full grid place-content-center border-[16px] border-white">
                                <div className="flex flex-col items-center text-white">
                                    <p className="text-white text-sm font-semibold">
                                        {new Date(totalTime * 1000)
                                            .toISOString()
                                            .slice(14, 19)}
                                    </p>
                                    <PlayArrow
                                        style={{ color: "white", fontSize: 28 }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full text-xs border-t">
                            <div className="flex-1 p-2 text-center">
                                <p>
                                    {new Date(totalTime * 1000)
                                        .toISOString()
                                        .slice(14, 19)}
                                </p>
                            </div>
                            <div className="flex-1 p-2 text-center">
                                <p>Add On</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
