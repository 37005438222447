import { ApiResponse, mutate, query } from './api'
import * as T from './media.type'
import * as Q from './media.query'

interface MediaApiInterface {
    getMedia(): ApiResponse<T.Media[]>
    getEbook(): ApiResponse<T.Media>
    uploadMedia(files: File[]): ApiResponse<T.Media[]>
    uploadEbook(file: File): ApiResponse<T.Media>
    uploadVideo(video: T.VideoInput): ApiResponse<T.Media[]>
}

export const MediaApi: MediaApiInterface = {
    getMedia() {
        return query({
            query: Q.getMedia,
        }).then(({ data }) => ({ data: data.media }))
    },
    getEbook() {
        return query({
            query: Q.getEbook,
        }).then(({ data }) => ({ data: data.ebook }))
    },
    uploadMedia(files) {
        return mutate({
            mutation: Q.uploadMedia,
            variables: { files }
        }).then(({ data }) => ({ data: data.uploadMedia }))
    },
    uploadEbook(file) {
        return mutate({
            mutation: Q.uploadEbook,
            variables: { file }
        }).then(({ data }) => ({ data: data.uploadEbook }))
    },
    uploadVideo(video) {
        return mutate({
            mutation: Q.uploadVideo,
            variables: { video }
        }).then(({ data }) => ({ data: data.uploadVideo }))
    },
}