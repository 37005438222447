import { createContext, useContext, useState } from "react";
import {
    Attachment,
    AttachmentInput,
    AttachmentsApi,
    CategoriesApi,
    Category,
    CategoryInput,
    Dashboard,
    DashboardApi,
    Deal,
    DealInput,
    DealsApi,
    Massage,
    MassageInput,
    Media,
    MediaApi,
    Muscle,
    MuscleInput,
    MusclesApi,
    User,
    UsersApi,
    VideoInput,
    Workout,
    WorkoutInput,
    WorkoutsApi,
} from "../api";
import { MassagesApi } from "../api/massages.api";
import { useAuthContext } from "./auth.context";

interface DataContextInterface {
    dashboard: Dashboard | null;
    users: User[] | null;
    categories: Category[] | null;
    category: Category | null;
    massages: Massage[] | null;
    massage: Massage | null;
    deals: Deal[] | null;
    deal: Deal | null;
    muscles: Muscle[] | null;
    muscle: Muscle | null;
    media: Media[] | null;
    ebook: Media | null;
    attachments: Attachment[] | null;
    attachment: Attachment | null;
    workouts: Workout[] | null;
    workout: Workout | null;
    isLoading: boolean;
    hasLoaded: boolean;
    hasError: boolean;
    hasLoadedUsers: boolean;
    hasLoadedCategories: boolean;
    hasLoadedMuscles: boolean;
    hasLoadedMassages: boolean;
    hasLoadedDeals: boolean;
    hasLoadedAttachments: boolean;
    hasLoadedMedia: boolean;
    hasLoadedEbook: boolean;
    hasLoadedWorkouts: boolean;
    getDashboard: () => Promise<void>;
    getUsers: () => Promise<void>;
    getCategories: () => Promise<void>;
    getCategory: (id: string) => Promise<void>;
    createCategory: (args: CategoryInput) => Promise<void>;
    updateCategory: (id: string, args: CategoryInput) => Promise<void>;
    getMuscles: () => Promise<void>;
    getMuscle: (id: string) => Promise<void>;
    createMuscle: (args: MuscleInput) => Promise<void>;
    updateMuscle: (id: string, args: MuscleInput) => Promise<void>;
    getMassages: () => Promise<void>;
    getMassage: (id: string) => Promise<void>;
    createMassage: (args: MassageInput) => Promise<void>;
    updateMassage: (id: string, args: MassageInput) => Promise<void>;
    getDeals: () => Promise<void>;
    getDeal: (id: string) => Promise<void>;
    createDeal: (args: DealInput) => Promise<void>;
    updateDeal: (id: string, args: DealInput) => Promise<void>;
    getAttachments: () => Promise<void>;
    getAttachment: (id: string) => Promise<void>;
    createAttachment: (args: AttachmentInput) => Promise<void>;
    updateAttachment: (id: string, args: AttachmentInput) => Promise<void>;
    getWorkouts: () => Promise<void>;
    getWorkout: (id: string) => Promise<void>;
    createWorkout: (args: WorkoutInput) => Promise<void>;
    updateWorkout: (id: string, args: WorkoutInput) => Promise<void>;
    updateFeaturedWorkout: (id: string, featured: boolean) => Promise<void>;
    getMedia: () => Promise<void>;
    getEbook: () => Promise<void>;
    uploadMedia: (files: File[]) => Promise<void>;
    uploadEbook: (file: File) => Promise<void>;
    uploadVideo: (video: VideoInput) => Promise<void>;
}

const DataContext = createContext<DataContextInterface | undefined>(undefined);

const useDataContext = () => useContext(DataContext) as DataContextInterface;

const DataProvider = ({ children }: { children: React.ReactNode }) => {
    const { user } = useAuthContext();
    const [dashboard, setDashboard] = useState<Dashboard | null>(null);
    const [users, setUsers] = useState<User[] | null>(null);
    const [hasLoadedUsers, setHasLoadedUsers] = useState(false);
    const [workouts, setWorkouts] = useState<Workout[] | null>(null);
    const [workout, setWorkout] = useState<Workout | null>(null);
    const [hasLoadedWorkouts, setHasLoadedWorkouts] = useState(false);
    const [categories, setCategories] = useState<Category[] | null>(null);
    const [category, setCategory] = useState<Category | null>(null);
    const [hasLoadedCategories, setHasLoadedCategories] = useState(false);
    const [muscles, setMuscles] = useState<Muscle[] | null>(null);
    const [muscle, setMuscle] = useState<Muscle | null>(null);
    const [hasLoadedMuscles, setHasLoadedMuscles] = useState(false);
    const [massages, setMassages] = useState<Massage[] | null>(null);
    const [massage, setMassage] = useState<Massage | null>(null);
    const [hasLoadedMassages, setHasLoadedMassages] = useState(false);
    const [deals, setDeals] = useState<Deal[] | null>(null);
    const [deal, setDeal] = useState<Deal | null>(null);
    const [hasLoadedDeals, setHasLoadedDeals] = useState(false);
    const [media, setMedia] = useState<Media[] | null>(null);
    const [ebook, setEbook] = useState<Media | null>(null);
    const [hasLoadedMedia, setHasLoadedMedia] = useState(false);
    const [hasLoadedEbook, setHasLoadedEbook] = useState(false);
    const [attachments, setAttachments] = useState<Attachment[] | null>(null);
    const [attachment, setAttachment] = useState<Attachment | null>(null);
    const [hasLoadedAttachments, setHasLoadedAttachments] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);

    const getDashboard = async () => {
        if (!user) throw Error("Please sign in");
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await DashboardApi.getDashboard(user.id);
            setDashboard(data);
            setHasLoaded(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- CATEGORIES ---------------- //
    const getCategories = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await CategoriesApi.getCategories();
            setCategories(data);
            setHasLoadedCategories(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getCategory = async (id: string) => {
        if (isLoading) return;
        setIsLoading(true);
        setCategory(null);
        setHasError(false);
        try {
            const { data } = await CategoriesApi.getCategory(id);
            setCategory(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const createCategory = async (args: CategoryInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await CategoriesApi.createCategory(args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateCategory = async (id: string, args: CategoryInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await CategoriesApi.updateCategory(id, args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- ATTACHMENTS ---------------- //
    const getAttachments = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await AttachmentsApi.getAttachments();
            setAttachments(data);
            setHasLoadedAttachments(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getAttachment = async (id: string) => {
        if (isLoading) return;
        setIsLoading(true);
        setAttachment(null);
        setHasError(false);
        try {
            const { data } = await AttachmentsApi.getAttachment(id);
            setAttachment(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const createAttachment = async (args: AttachmentInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await AttachmentsApi.createAttachment(args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateAttachment = async (id: string, args: AttachmentInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await AttachmentsApi.updateAttachment(id, args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- MUSCLES ---------------- //
    const getMuscles = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MusclesApi.getMuscles();
            setMuscles(data);
            setHasLoadedMuscles(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getMuscle = async (id: string) => {
        if (isLoading) return;
        setIsLoading(true);
        setMuscle(null);
        setHasError(false);
        try {
            const { data } = await MusclesApi.getMuscle(id);
            setMuscle(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const createMuscle = async (args: MuscleInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await MusclesApi.createMuscle(args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateMuscle = async (id: string, args: MuscleInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await MusclesApi.updateMuscle(id, args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- MASSAGES ---------------- //
    const getMassages = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MassagesApi.getMassages();
            setMassages(data);
            setHasLoadedMassages(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getMassage = async (id: string) => {
        if (isLoading) return;
        setIsLoading(true);
        setMassage(null);
        setHasError(false);
        try {
            const { data } = await MassagesApi.getMassage(id);
            setMassage(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const createMassage = async (args: MassageInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await MassagesApi.createMassage(args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateMassage = async (id: string, args: MassageInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await MassagesApi.updateMassage(id, args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };
    // ---------- DEALS ---------------- //
    const getDeals = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await DealsApi.getDeals();
            setDeals(data);
            setHasLoadedDeals(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getDeal = async (id: string) => {
        if (isLoading) return;
        setIsLoading(true);
        setMassage(null);
        setHasError(false);
        try {
            const { data } = await DealsApi.getDeal(id);
            setDeal(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const createDeal = async (args: DealInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await DealsApi.createDeal(args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateDeal = async (id: string, args: DealInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await DealsApi.updateDeal(id, args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- WORKOUTS ---------------- //
    const getWorkouts = async () => {
        if (!user) throw Error("Please sign in");
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await WorkoutsApi.getWorkouts();
            setWorkouts(data);
            setHasLoadedWorkouts(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getWorkout = async (id: string) => {
        if (!user) throw Error("Please sign in");
        if (isLoading) return;
        setWorkout(null);
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await WorkoutsApi.getWorkout(id);
            setWorkout(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const createWorkout = async (args: WorkoutInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await WorkoutsApi.createWorkout(args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateWorkout = async (id: string, args: WorkoutInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await WorkoutsApi.updateWorkout(id, args);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const updateFeaturedWorkout = async (id: string, featured: boolean) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            await WorkoutsApi.updateFeaturedWorkout(id, featured);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- USERS ---------------- //
    const getUsers = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await UsersApi.getUsers();
            setUsers(data);
            setHasLoadedUsers(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    // ---------- MEDIA ---------------- //
    const getMedia = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MediaApi.getMedia();
            setMedia(data);
            setHasLoadedMedia(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const getEbook = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MediaApi.getEbook();
            setEbook(data);
            setHasLoadedEbook(true);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const uploadMedia = async (files: File[]) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MediaApi.uploadMedia(files);
            setMedia(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const uploadEbook = async (file: File) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MediaApi.uploadEbook(file);
            setEbook(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const uploadVideo = async (video: VideoInput) => {
        if (isLoading) return;
        setIsLoading(true);
        setHasError(false);
        try {
            const { data } = await MediaApi.uploadVideo(video);
            setMedia(data);
        } catch (error: any) {
            console.log(error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DataContext.Provider
            value={{
                dashboard,
                users,
                categories,
                category,
                massages,
                massage,
                deals,
                deal,
                muscles,
                muscle,
                media,
                ebook,
                attachments,
                attachment,
                workouts,
                workout,
                isLoading,
                hasError,
                hasLoaded,
                hasLoadedUsers,
                hasLoadedCategories,
                hasLoadedMuscles,
                hasLoadedAttachments,
                hasLoadedMedia,
                hasLoadedEbook,
                hasLoadedMassages,
                hasLoadedDeals,
                hasLoadedWorkouts,
                getDashboard,
                getUsers,
                getCategories,
                getCategory,
                createCategory,
                updateCategory,
                getMassages,
                getMassage,
                createMassage,
                updateMassage,
                getDeals,
                getDeal,
                createDeal,
                updateDeal,
                getMuscles,
                getMuscle,
                createMuscle,
                updateMuscle,
                getAttachments,
                getAttachment,
                createAttachment,
                updateAttachment,
                getWorkouts,
                getWorkout,
                createWorkout,
                updateWorkout,
                updateFeaturedWorkout,
                getMedia,
                getEbook,
                uploadMedia,
                uploadEbook,
                uploadVideo,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, useDataContext, DataProvider };
