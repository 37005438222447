import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { RoleTypes } from '../api'
import { useAuthContext } from '../context/auth.context'

export const RequireAdmin = ({ children }: { children: JSX.Element }) => {
    const { user } = useAuthContext()
    let location = useLocation()

    // if (initializeUser) return (
    //     <p>Loading...</p>
    // )
        
    if (!user || !user.token) {
        return <Navigate to="/sign-in" state={{ from: location }} replace />
    }
    
    if (user && user.role !== RoleTypes.ADMIN) {
        return <Navigate to='/' state={{ from: location }} replace />
    }

    return children
}