import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    DataGridPro,
    GridActionsCellItem,
    GridOverlay,
} from "@mui/x-data-grid-pro";
// import { DataGrid, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid'
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
    CircularProgress,
    Fab,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { LoadingOverlay } from "../../../components";
import { useDataContext } from "../../../context";

export const Muscles = () => {
    const { isLoading, hasLoadedMuscles, hasError, muscles, getMuscles } =
        useDataContext();
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    useEffect(() => {
        !isLoading && !hasLoadedMuscles && getMuscles();
    }, []);

    return (
        <>
            {isLoading ? (
                <CircularProgress />
            ) : hasError ? (
                <h2>Error.</h2>
            ) : (
                muscles &&
                hasLoadedMuscles && (
                    <div className="pb-16 flex flex-col gap-4">
                        <h1 className="text-5xl text-cherny-primary">
                            {t("Muscles")}
                        </h1>
                        <DataGridPro
                            columns={[
                                {
                                    field: "title",
                                    headerName: "Title",
                                    flex: 1,
                                    valueFormatter: (params) => params.value.en,
                                },
                                {
                                    field: "createdAt",
                                    headerName: "Created At",
                                    valueFormatter: (params) =>
                                        t("dateTime", { val: params.value }),
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    getActions: (params) => [
                                        <GridActionsCellItem
                                            icon={<EditIcon />}
                                            onClick={() =>
                                                navigate(
                                                    `/muscles/edit/${params.row.id}`
                                                )
                                            }
                                            label="Edit"
                                        />,
                                        <GridActionsCellItem
                                            disabled={isLoading}
                                            icon={<DeleteIcon />}
                                            onClick={(e) =>
                                                console.log(e, params.row.id)
                                            }
                                            label="Delete"
                                        />,
                                    ],
                                },
                            ]}
                            rows={muscles}
                            // onRowClick={(e) => handleSelected(e.row)}
                            autoHeight
                            disableSelectionOnClick
                            pagination
                            pageSize={10}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            components={{
                                LoadingOverlay: LoadingOverlay,
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "createdAt", sort: "desc" },
                                    ],
                                },
                            }}
                            loading={isLoading}
                            className="shadow-mui bg-white"
                        />

                        <Fab
                            color="secondary"
                            sx={{
                                position: "fixed",
                                bottom: 16,
                                right: 16,
                            }}
                            onClick={() => navigate("/muscles/add")}
                        >
                            <Add />
                        </Fab>
                    </div>
                )
            )}
        </>
    );
};
