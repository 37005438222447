import { client } from '../utils/apollo'
import { ApiResponse, mutate, query } from './api'
import * as T from './massages.type'
import * as Q from './massages.query'

interface MassagesApiInterface {
    getMassages(): ApiResponse<T.Massage[]>
    getMassage(id: string): ApiResponse<T.Massage>
    createMassage(args: T.MassageInput): ApiResponse<{ success: boolean }>
    updateMassage(id: string, args: T.MassageInput): ApiResponse<{ success: boolean }>
}

export const MassagesApi: MassagesApiInterface = {
    getMassages() {
        return query({
            query: Q.getMassages
        }).then(({ data }) => ({ data: data.massages }))
    },
    getMassage(id) {
        return query({
            query: Q.getMassage,
            variables: { id }
        }).then(({ data }) => ({ data: data.massage }))
    },
    createMassage(args) {
        return mutate({
            mutation: Q.createMassage,
            variables: { args }
        }).then(({ data }) => ({ data: data.createMassage }))
    },
    updateMassage(id, args) {
        return mutate({
            mutation: Q.updateMassage,
            variables: { id, args }
        }).then(({ data }) => ({ data: data.updateMassage }))
    },
}