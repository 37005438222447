import { client } from '../utils/apollo'
import { ApiResponse, mutate, query } from './api'
import * as T from './deals.type'
import * as Q from './deals.query'

interface DealsApiInterface {
    getDeals(): ApiResponse<T.Deal[]>
    getDeal(id: string): ApiResponse<T.Deal>
    createDeal(args: T.DealInput): ApiResponse<{ success: boolean }>
    updateDeal(id: string, args: T.DealInput): ApiResponse<{ success: boolean }>
}

export const DealsApi: DealsApiInterface = {
    getDeals() {
        return query({
            query: Q.getDeals
        }).then(({ data }) => ({ data: data.deals }))
    },
    getDeal(id) {
        return query({
            query: Q.getDeal,
            variables: { id }
        }).then(({ data }) => ({ data: data.deal }))
    },
    createDeal(args) {
        return mutate({
            mutation: Q.createDeal,
            variables: { args }
        }).then(({ data }) => ({ data: data.createDeal }))
    },
    updateDeal(id, args) {
        return mutate({
            mutation: Q.updateDeal,
            variables: { id, args }
        }).then(({ data }) => ({ data: data.updateDeal }))
    },
}