import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    a11yProps,
    LanguageFields,
    StyledFilledInput,
    StyledTextField,
    TabPanel,
    UploadMedia,
} from "../../../../../components";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../../../../../context";
import { AttachmentInput, Language } from "../../../../../api";
import { Close } from "@mui/icons-material";

const HiddenInput = styled("input")({
    display: "none",
});

export const Attachment = () => {
    const { t, i18n } = useTranslation();
    const [titleEn, setTitleEn] = useState("");
    const [titleNl, setTitleNl] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionNl, setDescriptionNl] = useState("");
    const [open, setOpen] = useState(false);
    let navigate = useNavigate();
    const { id } = useParams();
    const {
        isLoading,
        hasError,
        attachment,
        hasLoadedMedia,
        getAttachment,
        getMedia,
        createAttachment,
        updateAttachment,
        media,
    } = useDataContext();
    const [mediaId, setMediaId] = useState("");

    useEffect(() => {
        !isLoading && !hasLoadedMedia && getMedia();
    }, []);

    const selectedMedia = useMemo(() => {
        return media?.find((med) => med.id === mediaId);
    }, [mediaId, media]);

    useEffect(() => {
        if (id) {
            getAttachment(id);
        }
    }, [id]);

    useEffect(() => {
        setTitleEn("");
        setTitleNl("");
        setDescriptionEn("");
        setDescriptionNl("");
    }, []);

    useEffect(() => {
        if (id && attachment) {
            setTitleEn(attachment.title.en);
            setDescriptionEn(attachment.description.en);
            setTitleNl(attachment.title.nl);
            setDescriptionNl(attachment.description.nl);
            setMediaId(attachment.media?.id ?? "");
        }
    }, [id, attachment]);

    const args: AttachmentInput = {
        title: {
            en: titleEn,
            nl: titleNl,
        },
        description: {
            en: descriptionEn,
            nl: descriptionNl,
        },
        media: mediaId,
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (attachment && id) {
            await updateAttachment(attachment.id, args);
        } else {
            await createAttachment(args);
        }
        if (!hasError || !isLoading) navigate("/attachments");
    };

    const disabled = useMemo(() => {
        return (
            !descriptionEn || !descriptionNl || !mediaId || !titleEn || !titleNl
        );
    }, [descriptionEn, descriptionNl, mediaId, titleEn, titleNl]);

    return (
        <form
            onSubmit={handleSubmit}
            className="relative pb-16 flex flex-col gap-4"
        >
            <h1 className="text-5xl text-cherny-primary">{t("Attachment")}</h1>
            <div className="flex flex-col gap-4 p-4 rounded shadow-mui bg-white">
                <h3>{t("Media")}</h3>
                {selectedMedia && (
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}${selectedMedia.medium}`}
                        alt=""
                        className="h-32 w-[213px] object-cover mx-auto"
                    />
                )}
                <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={() => setOpen(true)}
                >
                    Media
                </Button>
            </div>

            <LanguageFields
                titleEn={titleEn}
                titleNl={titleNl}
                setTitleEn={setTitleEn}
                setTitleNl={setTitleNl}
                descriptionEn={descriptionEn}
                descriptionNl={descriptionNl}
                setDescriptionEn={setDescriptionEn}
                setDescriptionNl={setDescriptionNl}
                hasDescription
            />
            {/* 
            <FormControl fullWidth>
                <StyledTextField
                    id="title"
                    type="text"
                    name="title"
                    label="Title"
                    value={titleEn}
                    variant="filled"
                    autoFocus
                    onChange={(e) => setTitleEn(e.target.value)}
                />
            </FormControl>

            <FormControl fullWidth>
                <StyledTextField
                    id="description"
                    type="text"
                    name="description"
                    label="Description"
                    value={descriptionEn}
                    variant="filled"
                    multiline
                    rows={4}
                    onChange={(e) => setDescriptionEn(e.target.value)}
                />
            </FormControl> */}

            <div className="fixed bottom-0 left-0 right-0 z-[999] p-4 bg-white shadow-mui">
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                    disabled={disabled}
                    fullWidth
                >
                    {attachment && id ? t("Update") : t("Create")}
                </LoadingButton>
            </div>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div className="relative">
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 16,
                        }}
                        aria-label="Sluiten"
                    >
                        <Close />
                    </IconButton>
                    <UploadMedia
                        modal
                        selectedMedia={mediaId}
                        handleMediaSelect={setMediaId}
                        setOpen={setOpen}
                    />
                </div>
            </Modal>
        </form>
    );
};
