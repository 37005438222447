import { gql } from '@apollo/client'

export const getDashboard = gql`
    query Dashboard($userId: String!) {
        featuredWorkouts {
            id
            title{
                en
                nl
            }
            massages {
                id
                time
                attachment {
                    id
                    title{
                        en
                        nl
                    }
                }
            }
        }
        user(userId: $userId) {
            createdWorkouts {
                id
                title{
                    en
                    nl
                }
                massages {
                    id
                    time
                    attachment {
                        id
                        title{
                            en
                            nl
                        }
                    }
                }
            }
        }
        categories {
            id
            title{
                en
                nl
            }
            workouts {
                id
                title{
                    en
                    nl
                }
                featured
                massages {
                    id
                    time
                    attachment {
                        id
                        title{
                            en
                            nl
                        }
                    }
                }
            }
        }
    }
`