import { gql } from '@apollo/client'

export const getWorkouts = gql`
    query Workouts {
        workouts {
            id
            featured
            title {
                en
                nl
            }
            media {
                id
                thumbnail
                medium
                large
            }
            category {
                id
                title{
                en
                nl
            }
            }
            massages {
                id
                description{
                    en
                    nl
                }
                time
            }
        }
    }
`

export const getWorkout = gql`
    query Workout($workoutId: String!) {
        workout(workoutId: $workoutId) {
            id
            featured
            title{
                en
                nl
            }
            description{
                en
                nl
            }
            media {
                id
                thumbnail
                medium
                large
            }
            category {
                id
                title{
                    en
                    nl
                }
            }
            massages {
                id
                description{
                    en
                    nl
                }
                time
                muscle {
                    id
                    title{
                        en
                        nl
                    }
                }
                attachment {
                    id
                    title{
                        en
                        nl
                    }
                    description{
                        en
                        nl
                    }
                }
                order
            }
        }
    }
`

export const createWorkout = gql`
    mutation CreateWorkout($args: WorkoutInput!) {
        createWorkout(args: $args) {
            success
        }
    }
`

export const updateWorkout = gql`
    mutation UpdateWorkout($id: String!, $args: WorkoutInput!) {
        updateWorkout(id: $id, args: $args) {
            success
        }
    }
`

export const updateFeaturedWorkout = gql`
    mutation UpdateFeaturedWorkout($id: String!, $featured: Boolean!) {
        updateFeaturedWorkout(id: $id, featured: $featured) {
            success
        }
    }
`