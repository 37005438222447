import { gql } from '@apollo/client'

export const getDeals = gql`
    query Deals {
        deals {
            id
            title {
                en
                nl
            }
            description {
                en
                nl
            }
            button {
                en
                nl
            }
            media {
                id
                thumbnail
                medium
                large
            }
            action {
                type
                options {
                    body
                    subject
                    recipient
                    url
                }
            }
        }
    }
`

export const getDeal = gql`
    query Deal($id: String!) {
        deal(id: $id) {
            id
            title {
                en
                nl
            }
            description {
                en
                nl
            }
            button {
                en
                nl
            }
            media {
                id
                thumbnail
                medium
                large
            }
            action {
                type
                options {
                    body
                    subject
                    recipient
                    url
                }
            }
        }
    }
`

export const createDeal = gql`
    mutation CreateDeal($args: DealInput!) {
        createDeal(args: $args) {
            success
        }
    }
`

export const updateDeal = gql`
    mutation UpdateDeal($id: String!, $args: DealInput!) {
        updateDeal(id: $id, args: $args) {
            success
        }
    }
`